import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { DatePipe, registerLocaleData } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpBackend,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import en from '@angular/common/locales/en';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  TranslateCompiler,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CoreModule } from '@core/core.module';
import { AuthInterceptor } from '@core/interceptor/auth.interceptor';
import { AppStorage } from '@shared/storage/app-storage';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderInterceptor } from '@core/interceptor/header.interceptor';
import { ViewLoaderComponent } from '@shared/components/view-loader/view-loader.component';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { ErrorPageComponent } from '@root/app/error-page/error-page.component';

registerLocaleData(en);

function httpLoaderFactory(httpBackend: HttpBackend) {
  return new TranslateHttpLoader(
    new HttpClient(httpBackend),
    './assets/i18n/',
    `.json?v=${new Date().getTime()}`
  );
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    HttpClientModule,
    /*
     * TranslateModule.forRoot must be called only on app module level
     * root module must  isolate to prevent child lazy loaded module from override app module translation
     * */
    TranslateModule.forRoot({
      defaultLanguage: AppStorage.getLanguage(),
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpBackend],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
      isolate: false,
    }),
    ViewLoaderComponent,
    ErrorPageComponent,
  ],
  providers: [
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
