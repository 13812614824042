import { Component, inject, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '@core/services/app.service';
import { BaseComponent } from '@root/app/shared/components/base/base.component';
import { SHARED_ICONS } from '@shared/constant/shared-icons';
import { takeUntil } from 'rxjs';
import { LanguageStoreService } from '@core/stores/language-store.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent extends BaseComponent implements OnInit {
  protected readonly appService = inject(AppService);
  private translateService = inject(TranslateService);
  private titleService = inject(Title);
  private matIconRegistry = inject(MatIconRegistry);
  private domSanitizer = inject(DomSanitizer);
  title = 'Security Operations System';
  private readonly languageStoreService = inject(LanguageStoreService);
  private matIconReg = inject(MatIconRegistry);
  constructor() {
    super();
    this.languageStoreService.initTranslation();
    this.matIconReg.setDefaultFontSetClass('material-symbols-outlined');
    //register custom icons
    this._registerCustomIcons();
  }

  private _registerCustomIcons() {
    // TODO to be removed and ues mat icon instead
    if (!SHARED_ICONS || !SHARED_ICONS.length) return;

    SHARED_ICONS.forEach((icon) => {
      this.matIconRegistry.addSvgIcon(
        icon.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(icon.path)
      );
    });
  }

  ngOnInit() {
    this.translateService
      .get('appTitle')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((translated: string) => {
        this.titleService.setTitle(translated);
      });
  }
}
